import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material';
import Navbar from './navbar';
import { HEADER } from '../../config';

// ----------------------------------------------------------------------
const MainStyle = styled('main')(({ theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT,
  paddingBottom: HEADER.MOBILE_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 72,
    paddingBottom: 72,
  },
}));
export default function DriverLayout() {
  return (
    <>
      <Navbar />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </>
  );
}
