import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function CashierLayout() {
  return (
    <Box component="main">
      <Outlet />
    </Box>
  );
}
