import { useState } from 'react';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFAutoComplete.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  options: PropTypes.array,
  isLoading: PropTypes.bool,
  freeSolo: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  filterOptions: PropTypes.func,
  onChangeCustom: PropTypes.func,
  onInputChangeCustom: PropTypes.func,
  clearOnBlur: PropTypes.bool,
  ListboxProps: PropTypes.object,
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  isOptionEqualToValue: PropTypes.func,
  renderOption: PropTypes.func,
  multiple: PropTypes.bool,
};

export const filter = createFilterOptions();

export default function RHFAutoComplete({
  name,
  options,
  onOpen,
  onClose,
  isLoading,
  onChangeCustom,
  filterOptions,
  freeSolo = false,
  clearOnBlur = true,
  onInputChangeCustom,
  ListboxProps,
  disabled,
  getOptionLabel,
  isOptionEqualToValue,
  renderOption,
  multiple,
  ...other
}) {
  const { control } = useFormContext();

  const [inputValue, setInputValue] = useState('');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          ListboxProps={ListboxProps}
          onChange={(event, newValue) => {
            if (onChangeCustom) {
              onChangeCustom(newValue, field);
            } else field.onChange(newValue);
          }}
          freeSolo={freeSolo}
          inputValue={inputValue}
          onInputChange={(e, newValue) => {
            if (onInputChangeCustom) onInputChangeCustom(setInputValue, newValue);
            else setInputValue(newValue);
          }}
          onClose={onClose}
          disabled={disabled}
          disablePortal
          onOpen={onOpen}
          clearOnBlur={clearOnBlur}
          loading={isLoading}
          loadingText={'Loading...'}
          options={options}
          getOptionLabel={getOptionLabel}
          filterOptions={filterOptions || undefined}
          isOptionEqualToValue={isOptionEqualToValue}
          multiple={multiple}
          renderOption={(props, option) =>
            renderOption ? (
              renderOption(props, option)
            ) : (
              <li {...props}>{option.inputValue ? option.inputValue : option} </li>
            )
          }
          renderInput={(params) => (
            <TextField {...params} fullWidth error={!!error} helperText={error?.message} {...other} />
          )}
        />
      )}
    />
  );
}
