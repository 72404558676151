import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { PATH_AFTER_LOGIN, PATH_AFTER_LOGIN_DRIVER, PATH_AFTER_LOGIN_CASHIER } from '../config';
// hooks
import useAuth from '../hooks/useAuth';
// routes

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  const currentRole = typeof user.role === 'string' ? user.role : user.role[0];

  // Possibly in the future, turn this into an object or array and loop through it instead of hardcoding like this

  if (isAuthenticated) {
    if (currentRole === 'superadmin' || currentRole === 'branch_admin') {
      return <Navigate to={PATH_AFTER_LOGIN} />;
    }
    if (currentRole === 'driver') {
      return <Navigate to={PATH_AFTER_LOGIN_DRIVER} />;
    }
    if (currentRole === 'cashier') {
      return <Navigate to={PATH_AFTER_LOGIN_CASHIER} />;
    }
  }

  return <>{children}</>;
}
