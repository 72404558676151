import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axiosBaseQuery';

import { HOST_API } from '../../config';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: axiosBaseQuery({
    baseUrl: HOST_API,
  }),
  tagTypes: ['User'],
  endpoints(builder) {
    return {
      createUser: builder.mutation({
        query: (value) => ({
          url: '/register',
          method: 'post',
          data: value,
        }),
        invalidatesTags: [{ type: 'User', id: 'LIST' }],
      }),
      getUsers: builder.query({
        query: ({ column, dir, rowsPerPage, debouncedSearch, role, page }) => ({
          url: `/api/users?orderByColumn[${column}]=${dir}&rowsPerPage=${rowsPerPage}&search=${debouncedSearch}&role=${role}&page=${page}`,
          method: 'get',
        }),
        providesTags: (result) =>
          result?.users
            ? [...result.users.map((user) => ({ type: 'User', id: user.id })), { type: 'User', id: 'LIST' }]
            : [{ type: 'User', id: 'LIST' }],
      }),
      getAUserById: builder.query({
        query: (id) => ({
          url: `/api/users/${id}`,
          method: 'get',
        }),
        providesTags: (result) => [{ type: 'User', id: result.id }],
      }),
      updateAUserById: builder.mutation({
        query: ({ id, values }) => ({ url: `/api/users/${id}`, method: 'put', data: values }),
        invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.id }],
      }),
      clearDriverEarnings: builder.mutation({
        query: ({ id }) => ({
          url: `/api/users/${id}/driver-info/earnings`,
          method: 'put',
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.id }],
      }),
      deleteUserById: builder.mutation({
        query: (id) => ({ url: `/api/users/${id}`, method: 'delete' }),
        invalidatesTags: (result, error, id) => [{ type: 'User', id }],
      }),
    };
  },
});

export const {
  useGetUsersQuery,
  useGetAUserByIdQuery,
  useCreateUserMutation,
  useUpdateAUserByIdMutation,
  useClearDriverEarningsMutation,
  useDeleteUserByIdMutation,
} = userApi;
