import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import {
  exchangeRateApi,
  branchApi,
  productApi,
  userApi,
  customerApi,
  inventoryApi,
  posApi,
  orderApi,
  dashboardApi,
  deliveryApi,
  expenseApi,
  expenseTypeApi,
} from './api';
import { rootPersistConfig, rootReducer } from './rootReducer';

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(
      userApi.middleware,
      customerApi.middleware,
      deliveryApi.middleware,
      productApi.middleware,
      branchApi.middleware,
      exchangeRateApi.middleware,
      inventoryApi.middleware,
      posApi.middleware,
      orderApi.middleware,
      dashboardApi.middleware,
      expenseApi.middleware,
      expenseTypeApi.middleware
    ),
});

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

export { store, persistor, dispatch, useSelector, useDispatch };
