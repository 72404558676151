import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axiosBaseQuery';

import { HOST_API } from '../../config';

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: axiosBaseQuery({
    baseUrl: HOST_API,
  }),
  tagTypes: ['Orders', 'OrderItems'],
  endpoints(builder) {
    return {
      getOrders: builder.query({
        query: ({ column, dir, startDate, endDate, rowsPerPage, page, invoice, status, paymentMethod, branch }) => ({
          url: `/api/sales?orderByColumn[${column}]=${dir}&rowsPerPage=${rowsPerPage}&page=${page}${
            startDate ? `&startDate=${startDate}` : ''
          }${endDate ? `&endDate=${endDate ?? ''}` : ''}${invoice ? `&invoice=${invoice ?? ''}` : ''}${
            status ? `&status=${status ?? ''}` : ''
          }${paymentMethod ? `&paymentMethod=${paymentMethod ?? ''}` : ''}${branch ? `&branch=${branch ?? ''}` : ''}`,
          method: 'GET',
        }),
        providesTags: (result) =>
          result.sales
            ? [...result.sales.map(({ id }) => ({ type: 'Orders', id })), { type: 'Orders', id: 'LIST' }]
            : [{ type: 'Orders', id: 'LIST' }],
      }),
      getOrderById: builder.query({
        query: ({ id }) => ({
          url: `/api/sales${id && `/${id}`}`,
          method: 'get',
        }),
        providesTags: (result) =>
          (result?.id && [{ type: 'Orders', id: result.id }]) || [{ type: 'Orders', id: 'LIST' }],
      }),
      getOrderItems: builder.query({
        query: ({ id }) => ({
          url: `/api/sales/${id}/items`,
          method: 'get',
        }),
        providesTags: (result) =>
          result?.items
            ? [...result?.items?.map(({ id }) => ({ type: 'OrderItems', id })), { type: 'OrderItems', id: 'LIST' }]
            : [{ type: 'OrderItems', id: 'LIST' }],
      }),
      getASaleDriver: builder.query({
        query: ({ id }) => ({
          url: `/api/sales/${id}/driver-info`,
          method: 'GET',
        }),
        providesTags: (result) => [{ type: 'Orders', id: result.id }],
      }),
      updateASaleStatus: builder.mutation({
        query: ({ id }) => ({
          url: `/api/sales/${id}/status`,
          method: 'PUT',
        }),
        invalidatesTags: ({ id }) => [{ type: 'Orders', id }],
      }),
      deleteASale: builder.mutation({
        query: ({ id }) => ({
          url: `/api/sales/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ({ id }) => [{ type: 'Orders', id }],
      }),
    };
  },
});

export const {
  useLazyGetOrdersQuery,
  useGetOrderByIdQuery,
  useLazyGetOrderByIdQuery,
  useGetASaleDriverQuery,
  useGetOrderItemsQuery,
  useUpdateASaleStatusMutation,
  useDeleteASaleMutation,
} = orderApi;
