import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axiosBaseQuery';

import { HOST_API } from '../../config';

export const expenseApi = createApi({
  reducerPath: 'expenseApi',
  baseQuery: axiosBaseQuery({
    baseUrl: HOST_API,
  }),
  tagTypes: ['Expense'],
  endpoints(builder) {
    return {
      getExpenses: builder.query({
        query: ({ rowsPerPage, debouncedSearch, page, filterStartDate, filterEndDate }) => ({
          url: `/api/expenses?rowsPerPage=${rowsPerPage}&page=${page}&search=${debouncedSearch}&startDate=${filterStartDate}&endDate=${filterEndDate}`,
          method: 'get',
        }),
        providesTags: (result) =>
          result?.expenses
            ? [...result.expenses.map((expense) => ({ type: 'Expense', id: expense.id })), { type: 'Expense', id: 'LIST' }]
            : [{ type: 'Expense', id: 'LIST' }],
      }),
      getAnExpenseById: builder.query({
        query: (id) => ({
          url: `/api/expenses/${id}`,
          method: 'get',
        }),
        providesTags: (result) => [{ type: 'Expense', id: result.id }],
      }),
      createAnExpense: builder.mutation({
        query: (values) => ({ url: `/api/expenses`, method: 'post', data: {...values} }),
        invalidatesTags: (result, error, arg) => [{ type: 'Expense', id: arg.id }],
      }),
      updateAnExpenseById: builder.mutation({
        query: ({ id, values }) => ({ url: `/api/expenses/${id}`, method: 'put', data: {...values} }),
        invalidatesTags: (result, error, arg) => [{ type: 'Expense', id: arg.id }],
      }),
      deleteExpenseById: builder.mutation({
        query: (id) => ({ url: `/api/expenses/${id}`, method: 'delete' }),
        invalidatesTags: ['Expense'],
      }),
    };
  },
});

export const {
  useGetExpensesQuery,
  useGetAnExpenseByIdQuery,
  useCreateAnExpenseMutation,
  useUpdateAnExpenseByIdMutation,
  useDeleteExpenseByIdMutation,
} = expenseApi;
