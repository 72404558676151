import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFNumberFormat.propTypes = {
  name: PropTypes.string,
  maxLength: PropTypes.number,
  allowNegative: PropTypes.bool,
  valueIsNumericString: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default function RHFNumberFormat({
  name,
  maxLength,
  allowNegative,
  valueIsNumericString = true,
  placeholder,
  ...other
}) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
        <NumericFormat
          {...field}
          {...other}
          onValueChange={(value, source) => {
            onChange(value.formattedValue);
          }}
          valueIsNumericString={valueIsNumericString}
          getInputRef={ref}
          allowNegative={allowNegative}
          fullWidth
          error={!!error}
          helperText={error?.message}
          customInput={TextField}
          placeholder={placeholder}
          inputProps={{
            maxLength,
          }}
        />
      )}
    />
  );
}
