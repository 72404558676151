import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
});

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (!error.response) {
//       throw error;
//     }
//     Promise.reject(
//       (error.response && error.response?.error) || error.response?.message || { message: 'Something went wrong' }
//     );
//   }
// );

export default axiosInstance;
