import { AppBar, Container, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import Iconify from '../../../components/Iconify';
import useAuth from '../../../hooks/useAuth';
import { PATH_AUTH, PATH_DRIVER } from '../../../routes/paths';

const NavbarContainer = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create('top', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  boxShadow: theme.customShadows.z8,
  padding: theme.spacing(0.5, 0),
  backgroundColor: theme.palette.background.default,
}));

function Navbar() {
  const theme = useTheme();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigateMyAccount = () => {
    navigate(PATH_DRIVER.myAccount);
    handleClose();
  };

  const handleSignOut = async () => {
    try {
      await logout();
      window.location.reload();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <NavbarContainer>
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center">
          <Typography variant="subtitle2" color="text.primary" textTransform="uppercase" align="left">
            Delivery
          </Typography>
          <IconButton
            size="medium"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            sx={{
              color: 'info',
              marginLeft: 1,
            }}
          >
            <Iconify icon={'mdi:account-circle'} />
          </IconButton>
          <Menu
            id="menu-appbar"
            variant="menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={handleNavigateMyAccount}
              sx={{
                ...theme.typography.body2,
              }}
            >
              My Account
            </MenuItem>
            <MenuItem
              onClick={handleSignOut}
              sx={{
                color: 'error.main',
                ...theme.typography.button,
              }}
            >
              Sign Out
            </MenuItem>
          </Menu>
        </Stack>
      </Container>
    </NavbarContainer>
  );
}

export default Navbar;
