import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axiosBaseQuery';

import { HOST_API } from '../../config';

export const branchApi = createApi({
  reducerPath: 'branchApi',
  baseQuery: axiosBaseQuery({
    baseUrl: HOST_API,
  }),
  tagTypes: ['Branch'],
  endpoints(builder) {
    return {
      getbranches: builder.query({
        query: () => ({
          url: `/api/branches`,
          method: 'get',
        }),
        providesTags: [{ type: 'Branch', id: 'LIST' }],
      }),
      getAllDriversOfBranch: builder.query({
        query: (name) => ({
          url: `/api/branches/${name}/drivers`,
          method: 'get',
        }),
      }),
      createABranch: builder.mutation({
        query: (name) => ({
          url: '/api/branches',
          method: 'post',
          data: {
            name,
          },
        }),
        invalidatesTags: [{ type: 'Branch', id: 'LIST' }],
      }),
      updateABranchByName: builder.mutation({
        query: ({ name, newName }) => ({ url: `/api/branches/${name}`, method: 'put', data: { newName } }),
        invalidatesTags: [{ type: 'Branch', id: 'LIST' }],
      }),
      deleteABranchByName: builder.mutation({
        query: (name) => ({ url: `/api/branches/${name}`, method: 'delete' }),
        invalidatesTags: [{ type: 'Branch', id: 'LIST' }],
      }),
    };
  },
});

export const {
  useGetbranchesQuery,
  useCreateABranchMutation,
  useGetAllDriversOfBranchQuery,
  useUpdateABranchByNameMutation,
  useDeleteABranchByNameMutation,
} = branchApi;
