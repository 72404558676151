import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axiosBaseQuery';

import { HOST_API } from '../../config';

export const customerApi = createApi({
  reducerPath: 'customerApi',
  baseQuery: axiosBaseQuery({
    baseUrl: HOST_API,
  }),
  tagTypes: ['Customer'],
  endpoints(builder) {
    return {
      getCustomers: builder.query({
        query: ({ rowsPerPage, search, searchByPhone, page }) => ({
          url: `/api/customers?rowsPerPage=${rowsPerPage}&search=${search}&searchByPhone=${searchByPhone}&page=${page}`,
          method: 'get',
        }),
        transformResponse: (response) => response,
        providesTags: (result) =>
          result?.data
            ? [
                ...result.data.map((customer) => ({ type: 'Customer', id: customer.id })),
                { type: 'Customer', id: 'LIST' },
              ]
            : [{ type: 'Customer', id: 'LIST' }],
      }),
      getACustomerById: builder.query({
        query: (id) => ({
          url: `/api/customers/${id}`,
          method: 'get',
        }),
        providesTags: (result) => [{ type: 'Customer', id: result.id }],
      }),
      updateACustomerById: builder.mutation({
        query: ({ id, ...data }) => ({
          url: `/api/customers/${id}`,
          method: 'put',
          data,
        }),
        invalidatesTags: (result, error, { id }) => [
          { type: 'Customer', id },
          { type: 'Customer', id: 'LIST' },
        ],
      }),
      exportCustomers: builder.mutation({
        query: () => ({
          url: `/api/customers/export`,
          method: 'GET',
          responseHandler: (response) => response.blob(),
        }),
      }),
    };
  },
});

export const {
  useGetCustomersQuery,
  useGetACustomerByIdQuery,
  useUpdateACustomerByIdMutation,
  useDeleteCustomerByIdMutation,
  useExportCustomersMutation,
} = customerApi;
