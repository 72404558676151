import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axiosBaseQuery';

import { HOST_API } from '../../config';

export const expenseTypeApi = createApi({
  reducerPath: 'expenseTypeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: HOST_API,
  }),
  tagTypes: ['ExpenseType'],
  endpoints(builder) {
    return {
      getExpenseTypes: builder.query({
        query: () => ({
          url: `/api/expense-types`,
          method: 'get',
        }),
        providesTags: (result) =>
          result?.expenseTypes
            ? [...result.expenseTypes.map((expenseType) => ({ type: 'ExpenseType', id: expenseType.id })), { type: 'ExpenseType', id: 'LIST' }]
            : [{ type: 'ExpenseType', id: 'LIST' }],
      }),
      getAnExpenseTypeById: builder.query({
        query: (id) => ({
          url: `/api/expense-types/${id}`,
          method: 'get',
        }),
        providesTags: (result) => [{ type: 'ExpenseType', id: result.id }],
      }),
      createAnExpenseType: builder.mutation({
        query: (values) => ({ url: `/api/expense-types`, method: 'post', data: values }),
        invalidatesTags: (result, error, arg) => [{ type: 'ExpenseType', id: arg.id }],
      }),
      updateAnExpenseTypeById: builder.mutation({
        query: ({ id, values }) => ({ url: `/api/expense-types/${id}`, method: 'put', data: values }),
        invalidatesTags: (result, error, arg) => [{ type: 'ExpenseType', id: arg.id }],
      }),
      deleteExpenseTypeById: builder.mutation({
        query: (id) => ({ url: `/api/expense-types/${id}`, method: 'delete' }),
        invalidatesTags: (result, error, id) => [{ type: 'ExpenseType', id }],
      }),
    };
  },
});

export const {
  useGetExpenseTypesQuery,
  useGetAnExpenseTypeByIdQuery,
  useCreateAnExpenseTypeMutation,
  useUpdateAnExpenseTypeByIdMutation,
  useDeleteExpenseTypeByIdMutation,
} = expenseTypeApi;
