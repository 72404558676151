// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_CASHIER = '/pos';
const ROOTS_DRIVER = '/driver';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_CASHIER = {
  root: ROOTS_CASHIER,
  orders: path(ROOTS_CASHIER, '/orders'),
  orderDetails: (id) => path(ROOTS_CASHIER, `/orders/${id}`),
  orderSummary: path(ROOTS_CASHIER, '/order-summary'),
  orderInvoice: path(ROOTS_CASHIER, '/order-invoice'),
};

export const PATH_DRIVER = {
  root: ROOTS_DRIVER,
  myAccount: path(ROOTS_DRIVER, '/my-account'),
  deliveries: path(ROOTS_DRIVER, '/deliveries'),
  deliveryDetails: (id) => path(ROOTS_DRIVER, `/deliveries/${id}`),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    setting: path(ROOTS_DASHBOARD, '/setting'),
    ecommerce: path(ROOTS_DASHBOARD, '/overview'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product'),
    shop: path(ROOTS_DASHBOARD, '/product/shop'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    checkout: path(ROOTS_DASHBOARD, '/product/checkout'),
    new: path(ROOTS_DASHBOARD, '/product/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/product/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/product/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/product/nike-air-force-1-ndestrukt'),
  },
  inventory: {
    root: path(ROOTS_DASHBOARD, '/inventory'),
    list: path(ROOTS_DASHBOARD, '/inventory/list'),
    new: path(ROOTS_DASHBOARD, '/inventory/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/inventory/${id}/edit`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    export: path(ROOTS_DASHBOARD, '/invoice/export'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  loyalty: {
    root: path(ROOTS_DASHBOARD, '/loyalty'),
    list: path(ROOTS_DASHBOARD, '/loyalty/list'),
    export: path(ROOTS_DASHBOARD, '/loyalty/export'),
    view: (id) => path(ROOTS_DASHBOARD, `/loyalty/${id}`),
  },
  expense: {
    root: path(ROOTS_DASHBOARD, '/expense'),
    list: path(ROOTS_DASHBOARD, '/expense/list'),
    new: path(ROOTS_DASHBOARD, '/expense/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/expense/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/expense/${id}/edit`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
