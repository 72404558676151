import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axiosBaseQuery';

import { HOST_API } from '../../config';

export const inventoryApi = createApi({
  reducerPath: 'inventoryApi',
  baseQuery: axiosBaseQuery({
    baseUrl: HOST_API,
  }),
  tagTypes: ['Inventory'],
  endpoints(builder) {
    return {
      getInventories: builder.query({
        query: ({ rowsPerPage, page, branch }) => ({
          url: `/api/inventories?branch=${branch}&rowsPerPage=${rowsPerPage}&page=${page}`,
          method: 'get',
        }),
        providesTags: ['Inventory'],
      }),
      getAnInventoryById: builder.query({
        query: (id) => ({
          url: `/api/inventories/${id}`,
          method: 'get',
        }),
        providesTags: (id) => [{ type: 'Inventory', id }, 'Inventory'],
      }),
      createAnInventory: builder.mutation({
        query: (inventory) => ({
          url: `/api/inventories`,
          method: 'post',
          data: { ...inventory, product: inventory.product.id },
        }),
        invalidatesTags: ['Inventory'],
      }),
      updateAnInventory: builder.mutation({
        query: (inventory) => ({
          url: `/api/inventories/${inventory.id}`,
          method: 'put',
          data: { stockAmount: inventory.stockAmount },
        }),
        invalidatesTags: ['Inventory'],
      }),
      deleteAnInventoryById: builder.mutation({
        query: (id) => ({ url: `/api/inventories/${id}`, method: 'delete' }),
        invalidatesTags: ['Inventory'],
      }),
    };
  },
});

export const {
  useGetInventoriesQuery,
  useGetAnInventoryByIdQuery,
  useCreateAnInventoryMutation,
  useUpdateAnInventoryMutation,
  useDeleteAnInventoryByIdMutation,
} = inventoryApi;
