import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle, Button } from '@mui/material';

import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node,
};

const useCurrentRole = () => {
  const auth = useAuth();

  if (auth.user) return typeof auth?.user.role === 'string' ? auth.user.role : auth.user.role[0];

  return '';
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const { logout } = useAuth();
  const currentRole = useCurrentRole();

  const isAnonymous = currentRole && currentRole === 'anonymous';

  const handleLogout = async () => {
    await logout();
  };

  if (!isAnonymous && !accessibleRoles.includes(currentRole)) {
    return (
      <Container
        maxWidth="sm"
        sx={{
          paddingTop: 8,
        }}
      >
        <Alert
          severity="error"
          action={
            <Button color="inherit" size="small" onClick={handleLogout}>
              LOGOUT
            </Button>
          }
        >
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
