import { useState, useEffect } from 'react';

// ----------------------------------------------------------------------

export default function useLocalStorage(key, defaultValue, options = {}) {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue === null) {
      return defaultValue;
    }
    try {
      const parsedValue = JSON.parse(storedValue);
      return options.deserialize ? options.deserialize(parsedValue) : parsedValue;
    } catch (error) {
      console.error(`Error parsing stored value for ${key}:`, error);
      return defaultValue;
    }
  });

  useEffect(() => {
    const listener = (e) => {
      if (e.storageArea === localStorage && e.key === key) {
        try {
          const newValue = JSON.parse(e.newValue);
          setValue(options.deserialize ? options.deserialize(newValue) : newValue);
        } catch (error) {
          console.error(`Error parsing storage event value for ${key}:`, error);
        }
      }
    };
    window.addEventListener('storage', listener);
    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, options.deserialize]);

  const setValueInLocalStorage = (newValue) => {
    setValue((currentValue) => {
      const valueToStore = typeof newValue === 'function' ? newValue(currentValue) : newValue;
      const valueToSerialize = options.serialize ? options.serialize(valueToStore) : valueToStore;
      localStorage.setItem(key, JSON.stringify(valueToSerialize));
      return valueToStore;
    });
  };

  return [value, setValueInLocalStorage];
}
