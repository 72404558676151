import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axiosBaseQuery';

import { HOST_API } from '../../config';

export const exchangeRateApi = createApi({
  reducerPath: 'exchangeRateApi',
  baseQuery: axiosBaseQuery({
    baseUrl: HOST_API,
  }),
  tagTypes: ['ExchangeRate'],
  endpoints(builder) {
    return {
      getExchangeRate: builder.query({
        query: () => ({
          url: `/api/exchange-rate`,
          method: 'get',
        }),
        providesTags: ['ExchangeRate'],
      }),
      setExchangeRate: builder.mutation({
        query: (rate) => ({
          url: `/api/exchange-rate`,
          method: 'post',
          data: {
            rate,
          },
        }),
        invalidatesTags: ['ExchangeRate'],
      }),
    };
  },
});

export const { useGetExchangeRateQuery, useSetExchangeRateMutation } = exchangeRateApi;
