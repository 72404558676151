import queryString from 'query-string';
import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axiosBaseQuery';

import { HOST_API } from '../../config';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: axiosBaseQuery({
    baseUrl: HOST_API,
  }),
  tagTypes: ['Dashboard'],
  endpoints(builder) {
    return {
      getAggregations: builder.query({
        query: ({ branch = null, startDate = null, endDate = null }) => {
          const query = queryString.stringify(
            { branch, startDate, endDate },
            {
              skipNull: true,
            }
          );
          return {
            url: `/api/dashboard/overview?${query}`,
            method: 'get',
          };
        },
        providesTags: (result, error, arg) => [
          {
            type: 'Dashboard',
            id: 'AGGREGATIONS',
          },
        ],
      }),
    };
  },
});

export const { useGetAggregationsQuery } = dashboardApi;
