import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axiosBaseQuery';

import { HOST_API } from '../../config';

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: axiosBaseQuery({
    baseUrl: HOST_API,
  }),
  tagTypes: ['Product'],
  endpoints(builder) {
    return {
      getProducts: builder.query({
        query: ({ column, dir, rowsPerPage, debouncedSearch, page }) => ({
          url: `/api/products?orderByColumn[${column}]=${dir}&rowsPerPage=${rowsPerPage}&search=${debouncedSearch}&page=${page}`,
          method: 'get',
        }),
        providesTags: ['Product'],
      }),
      getAllProductsOfBranch: builder.query({
        query: (branch) => ({
          url: `/api/products/${branch}/all-products`,
          method: 'get',
        }),
        providesTags: [{ type: 'Product', id: 'all-products' }, 'Product'],
      }),
      getAllAvailableProductsToPickForInventoryByBranch: builder.query({
        query: ({ branch }) => ({
          url: `/api/products/${branch}/available-products`,
          method: 'get',
        }),
        providesTags: [{ type: 'Product', id: 'available-products' }, 'Product'],
      }),
      getAProductById: builder.query({
        query: (id) => ({ url: `/api/products/${id}`, method: 'get' }),
        providesTags: ['Product'],
      }),
      createAProduct: builder.mutation({
        query: (productData) => ({
          url: '/api/products',
          method: 'post',
          data: {
            name: productData.name,
            stockPrice: productData.stock_price,
            pricePerUnit: productData.price_per_unit,
            imgUrl: productData.image_url,
          },
        }),
        invalidatesTags: [{ type: 'Product' }],
      }),
      updateAProduct: builder.mutation({
        query: ({ id, values }) => ({
          url: `/api/products/${id}`,
          method: 'put',
          data: {
            name: values.name,
            stockPrice: values.stock_price,
            pricePerUnit: values.price_per_unit,
            imgUrl: values.image_url,
          },
        }),
        invalidatesTags: ['Product'],
      }),
      deleteAProductById: builder.mutation({
        query: (id) => ({ url: `/api/products/${id}`, method: 'delete' }),
        invalidatesTags: ['Product'],
      }),
    };
  },
});

export const {
  useGetProductsQuery,
  useGetAllProductsOfBranchQuery,
  useLazyGetAllAvailableProductsToPickForInventoryByBranchQuery,
  useGetAProductByIdQuery,
  useCreateAProductMutation,
  useUpdateAProductMutation,
  useDeleteAProductByIdMutation,
} = productApi;
