import {createApi} from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axiosBaseQuery';

import {HOST_API} from '../../config';

export const posApi = createApi({
  reducerPath: 'posApi',
  baseQuery: axiosBaseQuery({
    baseUrl: HOST_API,
  }),
  tagTypes: ['Pos'],
  endpoints(builder) {
    return {
      createASale: builder.mutation({
        query: (sale) => ({
          url: `/api/sales`,
          method: 'post',
          data: sale,
        }),
        invalidatesTags: ['Pos'],
      }),
    };
  },
});

export const {useCreateASaleMutation} = posApi;
